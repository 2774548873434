import * as React from "react"
import Slider from "react-slick";
import zparse from "helpers/zparse";
import Image from "components/Image";

const TopHalf = props => {

	const { preTitle, title, content, photos, rawContent } = props;

	const settings = {
		arrows: false,
		dots: true,
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 10000,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
	};

	return (<section className="tophalf">
		<div className="grid-container">
			<div className="grid-x grid-padding-x align-center">
				<div className="cell small-12">
					<div className="tophalf-text">
						<h1 className="neighborhood-title text-center"><span>{preTitle}</span>{title}</h1>
						<hr className="brand" />
					</div>
					{(!!photos && photos?.length > 0) && <div className="cell small-12">
						<div className="photo-gallery-slider hide-for-medium">
							<Slider {...settings}>
								{photos.map((photo, index) => (
									<Image key={index} src={photo} />
								))}
							</Slider>
						</div>
						<div className="flx-photo-wrapper grid-x grid-padding-x show-for-medium">
							<div className="cell medium-6 columnize flx-item">
								<div className="flx-photo" style={{ backgroundImage: `url(${photos[0]})` }}></div>
							</div>
							<div className="cell medium-6 columnize flx-item">
								<div className="grid-x grid-padding-x">
									<div className="cell medium-5 columnize double-wrapper">
										<div className="flx-photo" style={{ backgroundImage: `url(${photos[1]})` }}></div>
										<div className="flx-photo" style={{ backgroundImage: `url(${photos[2]})` }}></div>
									</div>
									<div className="cell medium-7 columnize">
										<div className="flx-photo" style={{ backgroundImage: `url(${photos[3]})` }}></div>
									</div>
								</div>
							</div>
						</div>
					</div> }
				</div>
				{
				content && <div className="cell small-12 medium-10">
					<div className="tophalf-text">
						<div className="content-page">
							{zparse(content)}
						</div>
					</div>
				</div>
				}
				{rawContent}
			</div>
		</div>
	</section>)

}

export default TopHalf;