import * as React from "react"

import Layout from 'components/templates/Layout'
import FloatingContent from 'components/templates/global/FloatingContent';

const NotFoundPage = () => {
	return (
		<Layout>
			<FloatingContent legacy={true}>
				<h1>Page Not Found</h1>
				<p>We're sorry you're lost. There's plenty to explore in Authentic Walton though don't give up.</p>
			</FloatingContent>
		</Layout>
	)
}

export default NotFoundPage