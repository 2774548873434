import * as React from "react"
import { graphql } from 'gatsby';
import NotFound from 'pages/404';

import Layout from 'components/templates/Layout'
import TopHalf from "components/templates/neighborhood/TopHalf";
import Crowdriff from "components/templates/global/Crowdriff";

import useLiveData from 'hooks/useLiveData';

const ArticlePage = (props) => {
	const { edges: nodes } = props.data.allBlogPost;
	const posts = nodes.map(({ node }) => node);
	const { statusText: providerStatusText, status: providerStatus, response: providerResponse, visibilityChange } = useLiveData({
		type: 'BlogPost',
		apiParams: {
			uri: props.location.pathname, deleted: false, archived: false, approved: true, _join: "Topic"
		},
		staticData: props.data,
	});
	let post = posts[0];
	if (((providerResponse && providerResponse.data.length > 0) || !!post !== false) && providerStatusText === 'LOADED') {
		post = providerResponse.data[0];
	}
	if (providerResponse && visibilityChange) post = providerResponse.data[0];
	if (!!post === false && providerStatus >= 2) return <NotFound />;
	// if (props.providerStatus < 2){// This prevents any flash of content until the API is loaded -- but if the API takes a while to respond the effect would be the page loads slower. You could also use state to reveal content with a transition
	// 	return null;
	// }
	return (
		<div className={`loadWrap ${providerStatus >= 2 ? 'loaded' : ''}`}>
			{providerStatus >= 2 &&
				<Layout headerBackgroundImage={post?.photo}>
					<TopHalf
						title={post.title}
						content={post.body || ''}
					/>
					<Crowdriff hash={`980a78db817a3319`} />
				</Layout>
			}
		</div>
	)
}

export default ArticlePage;

export { Head } from 'components/templates/Head';

export const query = graphql`
	query blogGetQuery ($permalink:String) {
	  allBlogPost(filter: {permalink: {eq: $permalink}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        title
	        intro
	        body
			photo
	        Topic {
	          object {
	            name
	          }
	        }
	      }
	    }
	  }
	}
`