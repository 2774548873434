import * as React from "react"
const Layout = ({children}, legacy = false) => {
	return (
		<section className="floating-header">
			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					<div className="cell small-12">
						{children}
					</div>
				</div>
			</div>
		</section>
	)
}
export default Layout
