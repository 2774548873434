import * as React from 'react';
import useLiveData from 'hooks/useLiveData';
import { useStaticQuery, graphql } from 'gatsby';
import { parse } from 'node-html-parser';
import reactParse from 'html-react-parser';

const buildTag = (node) => {
	if (typeof node === 'string')return reactParse(node);
	let tagName = 'meta';
	for (let attribute in node) {
		if (attribute.localeCompare('tagname', 'en', {sensitivity: 'base'}) === 0){
			tagName = attribute.toLowerCase();
			delete node[attribute];
		}
	}
	const parsed = React.createElement(tagName, node);
	return parsed;
}

export const Head = (props) => {
	const data = useStaticQuery(graphql`
		query htmlHead {
		  allContentPage {
		    edges {
		      node {
		        uri
		        pageTitle
		        meta
		      }
		    }
		  }
		  allPressRelease {
		    edges {
		      node {
		        uri
		        headline
		        excerpt
		      }
		    }
		  }
		  allBlogPost {
			edges {
				node {
					uri
					title
					intro
				}
			}
		  }
		  allEventsEvent {
			edges {
				node {
					uri
					name
					description
				}
			}
		  }
		  allBeachNeighborhood {
			edges {
				node {
					uri
					name
					featuredDescription
				}
			}
		  }
		  allSimpleviewListing {
			edges {
				node {
					uri
					companyName
					description
				}
			}
		  }
		  allSimpleviewCategory {
			edges {
				node {
					uri
					name
					description
				}
			}
		  }
		  site {
		    siteMetadata {
		      title
		      siteUrl
		    }
		  }
		}
	`);
	var LiveDataParams = {
		type: 'ContentPage',
		method: 'all',
		apiParams: {
			_include: 'uri,pageTitle,meta',
		},
		staticData: data,
	}
	var contentType = 'ContentPage';
	if (props.location.pathname.indexOf('/press/') !== -1 && props.location.pathname !== '/press/') {
		contentType = 'PressRelease';
		LiveDataParams = {
			type: 'PressRelease',
			method: 'all',
			apiParams: {
				_include: 'uri,headline,excerpt',
			},
			staticData: data,
		}
	}
	if (props.location.pathname.indexOf('/blog/') !== -1 && props.location.pathname !== '/blog/') {
		contentType = 'BlogPost';
		LiveDataParams = {
			type: 'BlogPost',
			method: 'all',
			apiParams: {
				_include: 'uri,title,intro',
			},
			staticData: data,
		}
	}
	if (props.location.pathname.indexOf('/events/') !== -1 && props.location.pathname !== '/events/') {
		contentType = 'EventsEvent';
		LiveDataParams = {
			type: 'EventsEvent',
			method: 'all',
			apiParams: {
				_include: 'uri,name,intro',
			},
			staticData: data,
		}
	}
	if (props.location.pathname.indexOf('/explore/') !== -1 && props.location.pathname !== '/explore/') {
		contentType = 'BeachNeighborhood';
		LiveDataParams = {
			type: 'BeachNeighborhood',
			method: 'all',
			apiParams: {
				_include: 'uri,name,featuredDescription',
			},
			staticData: data,
		}
	}
	if (props.location.pathname.indexOf('/listing/') !== -1 && props.location.pathname !== '/listing/') {
		contentType = 'SimpleviewListing';
		LiveDataParams = {
			type: 'SimpleviewListing',
			method: 'all',
			apiParams: {
				_include: 'uri,companyName,description',
			},
			staticData: data,
		}
	}
	if (props.location.pathname.indexOf('/listings/') !== -1 && props.location.pathname !== '/listings/') {
		contentType = 'SimpleviewCategory';
		LiveDataParams = {
			type: 'SimpleviewCategory',
			method: 'all',
			apiParams: {
				_include: 'uri,name,description',
			},
			staticData: data,
		}
	}
	const {response: providerResponse} = useLiveData(LiveDataParams);

	// if (!providerResponse)return null;
	let pages = providerResponse.data.filter(item => item.uri === props.location.pathname);
	const siteURL = data.site.siteMetadata.siteUrl;
	let pageTitle = data.site.siteMetadata.title;
	let shareImage = data.site.siteMetadata.shareImage;
	// let description = data.site.siteMetadata.description;
	const meta = [];
	switch(contentType) {
		case 'ContentPage':
			if (pages.length > 0) {
				const pageData = pages[0];
				pageTitle = pageData.pageTitle;
				if (pageData.meta) {
					if (typeof pageData.meta === 'string') {
						const root = parse(pageData.meta);
						root.querySelectorAll('meta').forEach((node) => {
							meta.push(node.attributes);
						});
						if (root.querySelector('title')){
							pageTitle = root.querySelector('title').text;
						};
					}
				}
			} else {
				meta.push(`<title>${pageTitle}</title>`);
			}
			break;
		case 'PressRelease':
			pages = providerResponse.data.filter(item => item.uri === props.location.pathname);
			if (pages.length > 0) {
				const pageData = pages[0];
				let titleParts = pageData.headline.toLowerCase().split(" ");
				titleParts.forEach((part,index) => {
					titleParts[index] = part[0].toUpperCase() + part.substring(1);
				});
				pageTitle = `${titleParts.join(' ')} - ${data.site.siteMetadata.title}`;
				if (pageData.excerpt) {
					if (typeof pageData.excerpt === 'string') {
						let descriptionObject = {
							name: 'description',
							content: pageData.excerpt,
						}
						meta.push(descriptionObject);
					}
				} else if (pageData.meta) {
					if (typeof pageData.meta === 'string') {
						const root = parse(pageData.meta);
						root.querySelectorAll('meta').forEach((node) => {
							meta.push(node.attributes);
						});
						if (root.querySelector('title')){
							pageTitle = root.querySelector('title').text;
						};
					}
				}
			} else {
				meta.push(`<title>${pageTitle}</title>`);
			}
			break;
		case 'BlogPost':
			pages = providerResponse.data.filter(item => item.uri === props.location.pathname);
			if (pages.length > 0) {
				const pageData = pages[0];
				let titleParts = pageData.title.toLowerCase().split(" ");
				titleParts.forEach((part,index) => {
					titleParts[index] = part[0].toUpperCase() + part.substring(1);
				});
				pageTitle = `${titleParts.join(' ')} - ${data.site.siteMetadata.title}`;
				if (pageData.intro) {
					if (typeof pageData.intro === 'string') {
						let descriptionObject = {
							name: 'description',
							content: pageData.intro,
						}
						meta.push(descriptionObject);
					}
				} else if (pageData.meta) {
					if (typeof pageData.meta === 'string') {
						const root = parse(pageData.meta);
						root.querySelectorAll('meta').forEach((node) => {
							meta.push(node.attributes);
						});
						if (root.querySelector('title')){
							pageTitle = root.querySelector('title').text;
						};
					}
				}
			} else {
				meta.push(`<title>${pageTitle}</title>`);
			}
			break;
		case 'EventsEvent':
			pages = providerResponse.data.filter(item => item.uri === props.location.pathname);
			if (pages.length > 0) {
				const pageData = pages[0];
				let titleParts = pageData.name.toLowerCase().split(" ");
				titleParts.forEach((part,index) => {
					titleParts[index] = part[0].toUpperCase() + part.substring(1);
				});
				pageTitle = `${titleParts.join(' ')} - ${data.site.siteMetadata.title}`;
				if (pageData.description) {
					if (typeof pageData.description === 'string') {
						let descriptionObject = {
							name: 'description',
							content: pageData.description,
						}
						meta.push(descriptionObject);
					}
				} else if (pageData.meta) {
					if (typeof pageData.meta === 'string') {
						const root = parse(pageData.meta);
						root.querySelectorAll('meta').forEach((node) => {
							meta.push(node.attributes);
						});
						if (root.querySelector('title')){
							pageTitle = root.querySelector('title').text;
						};
					}
				}
			} else {
				meta.push(`<title>${pageTitle}</title>`);
			}
			break;
		case 'BeachNeighborhood':
			pages = providerResponse.data.filter(item => item.uri === props.location.pathname);
			if (pages.length > 0) {
				const pageData = pages[0];
				let titleParts = pageData.name.toLowerCase().split(" ");
				titleParts.forEach((part,index) => {
					titleParts[index] = part[0].toUpperCase() + part.substring(1);
				});
				pageTitle = `${titleParts.join(' ')} - ${data.site.siteMetadata.title}`;
				if (pageData.featuredDescription) {
					if (typeof pageData.featuredDescription === 'string') {
						let descriptionObject = {
							name: 'description',
							content: pageData.featuredDescription,
						}
						meta.push(descriptionObject);
					}
				} else if (pageData.meta) {
					if (typeof pageData.meta === 'string') {
						const root = parse(pageData.meta);
						root.querySelectorAll('meta').forEach((node) => {
							meta.push(node.attributes);
						});
						if (root.querySelector('title')){
							pageTitle = root.querySelector('title').text;
						};
					}
				}
			} else {
				meta.push(`<title>${pageTitle}</title>`);
			}
			break;
		case 'SimpleviewListing':
			pages = providerResponse.data.filter(item => item.uri === props.location.pathname);
			if (pages.length > 0) {
				const pageData = pages[0];
				let titleParts = pageData.companyName.toLowerCase().split(" ");
				titleParts.forEach((part,index) => {
					titleParts[index] = part[0].toUpperCase() + part.substring(1);
				});
				pageTitle = `${titleParts.join(' ')} - ${data.site.siteMetadata.title}`;
				if (pageData.description) {
					if (typeof pageData.description === 'string') {
						let descriptionObject = {
							name: 'description',
							content: pageData.description,
						}
						meta.push(descriptionObject);
					}
				} else if (pageData.meta) {
					if (typeof pageData.meta === 'string') {
						const root = parse(pageData.meta);
						root.querySelectorAll('meta').forEach((node) => {
							meta.push(node.attributes);
						});
						if (root.querySelector('title')){
							pageTitle = root.querySelector('title').text;
						};
					}
				}
			} else {
				meta.push(`<title>${pageTitle}</title>`);
			}
			break;
		case 'SimpleviewCategory':
			pages = providerResponse.data.filter(item => item.uri === props.location.pathname);
			if (pages.length > 0) {
				const pageData = pages[0];
				let titleParts = pageData.name.toLowerCase().split(" ");
				titleParts.forEach((part,index) => {
					titleParts[index] = part[0].toUpperCase() + part.substring(1);
				});
				pageTitle = `${titleParts.join(' ')} - ${data.site.siteMetadata.title}`;
				if (pageData.description) {
					if (typeof pageData.description === 'string') {
						let descriptionObject = {
							name: 'description',
							content: pageData.description,
						}
						meta.push(descriptionObject);
					}
				} else if (pageData.meta) {
					if (typeof pageData.meta === 'string') {
						const root = parse(pageData.meta);
						root.querySelectorAll('meta').forEach((node) => {
							meta.push(node.attributes);
						});
						if (root.querySelector('title')){
							pageTitle = root.querySelector('title').text;
						};
					}
				}
			} else {
				meta.push(`<title>${pageTitle}</title>`);
			}
			break;
		default:
			meta.push(`<title>${pageTitle}</title>`);
	}
	if (meta.filter(node => node.name === 'description').length > 0) {
		const description = meta.filter(node => node.name === 'description')[0].content;
		if (meta.filter(node => node.property === 'og:description').length === 0)meta.push({property: 'og:description', content: description});
	}
	if (shareImage && meta.filter(node => node.property === 'og:image').length === 0) {
		meta.push({property: 'og:image', content: `${siteURL}/${shareImage}`});
	}
	meta.push(`<title>${pageTitle}</title>`);
	return (
		<>
			{meta.map((node, index) => buildTag(node))}
		</>
	)
}